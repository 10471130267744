import { Link } from "gatsby"
import React from "react"
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  useDisclosure,
} from "@chakra-ui/core"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const SvgButton = styled.button`
  height: 40px;
  width: 40px;
  border: none;
  background: none;
  cursor: pointer:
  outline:none;
  margin-right: 0.75rem;
&:active {
    outline: none;
  }
  &:focus {
      outline: none;
      -moz-outline-style: none;
  }
  @media (min-width: 640px) {
    display: none;
  }
`

const MenuHeader = styled.h3`
  color: #312e2e;
  font-size: 0.6rem;
  margin-top: 1rem;
  @media (max-width: 340px) {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`

const NavLink = styled(Link)`
  color: #312e2e;
  font-size: 0.8rem;
  font-weight: normal;
  width: 100%;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  padding: 0.5rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #d1d1d1;
    border-radius: 4px;
  }
  &.current-page {
    background: #d1d1d1;
    border-radius: 4px;
  }
  @media (max-width: 340px) {
    font-size: 0.7rem;
    line-height: 0.8;
  }
`

const ExtLink = styled.a`
  color: #312e2e;
  font-size: 0.8rem;
  font-weight: normal;
  width: 100%;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  padding: 0.5rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #d1d1d1;
    border-radius: 4px;
  }
  &.current-page {
    background: #d1d1d1;
    border-radius: 4px;
  }
  @media (max-width: 340px) {
    font-size: 0.7rem;
    line-height: 0.8;
  }
`

const GiveLink = styled.a`
  color: #312e2e;
  font-size: 0.8rem;
  font-weight: normal;
  width: 100%;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  padding: 0.5rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #d1d1d1;
    border-radius: 4px;
  }
  @media (max-width: 340px) {
    font-size: 0.7rem;
    line-height: 0.8;
  }
`

function BurgerMenuCornville() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <>
      <SvgButton ref={btnRef} onClick={onOpen}>
        <svg fill="none" viewBox="0 0 24 24" stroke="#f7fafc" class="w-8 h-8">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </SvgButton>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        isFullHeight="true"
      >
        <DrawerOverlay />
        <DrawerContent overflow="scroll">
          <DrawerCloseButton />

          <DrawerBody>
            <MenuHeader>Locations</MenuHeader>
            <ul
              css={css`
                margin-top: 0.5rem;
              `}
            >
              <NavLink to="/surprise">Surprise</NavLink>
              <NavLink to="/phoenix">Phoenix</NavLink>
              <NavLink to="/cornville">Cornville</NavLink>
              <NavLink to="/elmirage">El Mirage</NavLink>
              <NavLink to="/">All Locations</NavLink>
            </ul>
            <MenuHeader>Connect</MenuHeader>
            <ul
              css={css`
                margin-top: 0.5rem;
              `}
            >
              <NavLink to="/cornville/connect">Connect With Us</NavLink>
              <NavLink to="/cornville/leadership">Meet Our Staff</NavLink>
              <NavLink to="/cornville/bible-groups">Bible Groups</NavLink>
            </ul>
            <MenuHeader>Resources</MenuHeader>
            <ul
              css={css`
                margin-top: 0.5rem;
              `}
            >
              <ExtLink
                href="https://youtube.com/playlist?list=PLcw5WfAS2DN4cytsMSQuCZTFQ6ViPhcd3&si=1_Z39GLHTMmC_6t5"
                target="_blank"
              >
                Podcast
              </ExtLink>
              <ExtLink href="https://blog.crosschurchcares.com" target="_blank">
                Blog
              </ExtLink>
            </ul>
            <MenuHeader>Giving</MenuHeader>
            <ul
              css={css`
                margin-top: 0.5rem;
              `}
            >
              <GiveLink href="https://pushpay.com/pay/palmvistasurprise/3wVi2Zku2LnNwZiQ57OSwA">
                Give Now
              </GiveLink>
            </ul>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default BurgerMenuCornville
